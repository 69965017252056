// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/_css-loader@3.6.0@css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/planA/bubbles-left-left.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/planA/bubbles-left-middle.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/planA/bubbles-left-right.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/planA/bubbles-right-left.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/planA/bubbles-right-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".com-bubbles[data-v-e5bc5504]{width:auto;height:100%}.com-bubbles .left-bubbles[data-v-e5bc5504]{height:.69333rem;display:flex;justify-content:center;align-items:center}.com-bubbles .left-bubbles .left[data-v-e5bc5504]{width:.4rem;height:.69333rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat top;background-size:cover}.com-bubbles .left-bubbles .content[data-v-e5bc5504]{width:auto;height:.69333rem;font-size:.42667rem;line-height:.69333rem;color:#fff;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") repeat top;background-size:cover}.com-bubbles .left-bubbles .right[data-v-e5bc5504]{width:.4rem;height:.69333rem;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat top;background-size:cover}.com-bubbles .right-bubbles[data-v-e5bc5504]{height:100%;width:auto;display:flex;justify-content:center;align-items:center}.com-bubbles .right-bubbles .left[data-v-e5bc5504]{width:.4rem;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") no-repeat top;background-size:cover}.com-bubbles .right-bubbles .content[data-v-e5bc5504]{width:auto;height:100%;font-size:.42667rem;line-height:.69333rem;color:#fff;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") repeat top;background-size:cover}.com-bubbles .right-bubbles .right[data-v-e5bc5504]{width:.4rem;height:100%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") no-repeat top;background-size:cover}", ""]);
// Exports
module.exports = exports;
