<template>
  <div class="com-bubbles">
      <div class="left-bubbles" v-if=" type == 'left'">
            <div class="left"></div>
            <div class="content">{{content}}</div>
            <div class="right"></div>
      </div>
      <div class="right-bubbles" v-if=" type == 'right'">
            <div class="left"></div>
            <div class="content">{{content}}</div>
            <div class="right"></div>
      </div>
  </div>
</template>

<script>
export default {
  name: 'bubbles',
  props:{
      type:{
          type: String,
          default: 'left'
      },
      content:{
          type: String,
          default:''
      }
  }
}
</script>
<style lang="scss" scoped>
    .com-bubbles {
        width: auto;
        height: 100%;
        .left-bubbles {
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            .left {
                width: 15px;
                height: 26px;
                background: url(../../assets/planA/bubbles-left-left.png) no-repeat top center;
                background-size: cover;
            }
            .content {
                width: auto;
                height: 26px;
                font-size:16px;
                line-height: 26px;
                color:rgba(255,255,255,1);
                background-size: cover;
                background: url(../../assets/planA/bubbles-left-middle.png) repeat top center;
                background-size: cover;
            }
            .right {
                width: 15px;
                height: 26px;
                background: url(../../assets/planA/bubbles-left-right.png) no-repeat top center;
                background-size: cover;
            }
        }
        .right-bubbles {
            height: 100%;
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            .left {
                width: 15px;
                height: 100%;
                background: url(../../assets/planA/bubbles-right-left.png) no-repeat top center;
                background-size: cover;
            }
            .content {
                width: auto;
                height: 100%;
                font-size:16px;
                line-height: 26px;
                color:rgba(255,255,255,1);
                background-size: cover;
                background: url(../../assets/planA/bubbles-left-middle.png) repeat top center;
                background-size: cover;
            }
            .right {
                width: 15px;
                height: 100%;
                background: url(../../assets/planA/bubbles-right-right.png) no-repeat top center;
                background-size: cover;
            }
        }
        
    }
</style>