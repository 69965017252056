<template>
  <div class="plan-b">
      <!-- <div class="nav">
          <div class="top-title">
            <div class="top-title-iocn"></div>
            <div class="top-title-content">{{goodsInfo.name}}</div>
        </div>
      </div> -->
      <div class="content-one">
          <div class="swipe">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" @change="topSwipeChange">
                <van-swipe-item v-for=" (item,index) in goodsList " :key="index">
                    <van-image
                        class="img"
                        width="100%"
                        height="100%"
                        fit="fill"
                        :src="item.img"
                    />
                    <div class="bg-color">
                        
                    </div>
                </van-swipe-item>
            </van-swipe>
            <div class="goods-name"> {{ goodsInfo.name}} </div>
          </div>
          <div class="dots">
                <span class="dot" v-if="goodsList.length > 1" :class="{active:currentIndex === index}" v-for="(item,index) in goodsList"></span>
            </div>
        </div>
      <div class="content-two">
          <div class="goods-info">
                <div class="goods-detail">
                    <div class="detail"><p class="title">商品唯一编码</p> <p class="content">{{goodsInfo.num}}</p></div>
                    <div class="detail"><p class="title">最新查询时间</p> <p class="content">{{goodsInfo.newTime}}</p></div>
                    <div class="detail last"><p class="title">查询次数</p> <p class="content">{{goodsInfo.queryNum}}次</p> </div>
                    <p class="tip" v-if="goodsInfo.queryNum > 0">已多次查验,谨防假冒</p>
                </div>
            </div>
          <div class="introduce">
              <div class="introduce-title">
                   <div class="title">
                      <div class="title-name">产品介绍</div>
                    </div>
              </div>
              <div class="introduce-info">
                    <div class="introduce-detail">
                        <div class="detail" v-for="(item,index) in productInfo">
                            <p class="title">{{item.name}}</p><p class="content">{{item.context}}</p>
                        </div>
                    </div>
                    <div class="right-icon"></div>
                    <div class="left-icon"></div>
              </div>

              <div class="logistics-title">
                    <div class="title">
                       <div class="title-name">物流地图</div>
                    </div>
              </div>
              <div class="map-info">
                  <div class="map">
                      <div  id="map"></div>
                  </div>
                  
              </div>
               <div class="grow-title">
                    <div class="title">
                       <div class="title-name">成长过程</div>
                    </div>
              </div>
              <div class="grow-info">
                  <div v-for="(item,index) in growthList" :key="index">
                    <div class="info-content-left" v-if="(index+1)%2 == 1">
                        <div class="info-img">
                            <div class="border"></div>
                            <img class="img" :src="item.imgUrl"/>
                            <div class="layer"></div>
                        </div>
                        <div class="info">
                            <div class="bubbles">
                                {{ item.processName }}
                            </div>
                            <p class="date">{{ item.startTimeStr }}</p>
                            <!-- <div class="arrow">
                                <div class="arrow-icon" @click="toDetail(item.processId)"></div>
                            </div> -->
                            <div class="left-bottom"></div>
                        </div>
                    </div>
                    <div class="info-content-right" v-if="(index+1)%2 == 0">
                        <div class="info-img">
                            <div class="border"></div>
                            <img class="img" :src="item.imgUrl"/>
                            <div class="layer"></div>
                        </div>
                        <div class="info">
                            <div class="bubbles">
                                {{ item.processName }}
                            </div>
                            <p class="date">{{ item.startTimeStr }}</p>
                            <!-- <div class="arrow">
                                <div class="arrow-icon" @click="toDetail(item.processId)"></div>
                            </div> -->
                            <div class="right-bottom"></div>
                        </div>
                    </div>
                  </div>
              </div>
              <!-- 企业介绍 -->
              <div class="company-title" v-if="companyInfo.companyName">
                    <div class="title">
                       <div class="title-name">企业介绍</div>
                    </div>
              </div>
              <div class="company-info" v-if="companyInfo.companyName">
                <div class="swipe" v-if="companyInfo.imgList">
                     <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                        <van-swipe-item>
                            <van-image
                                width="100%"
                                height="100%"
                                fit="fill"
                                :src="companyInfo.imgList"
                            />
                            <div class="bg-color">
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                    <div class="left-icon"></div>
                </div>
                 
                <div class="company-content">
                    <div class="company">
                        <p class="name">{{companyInfo.companyName}}</p>
                        <p id="content" :class="[{'box': showBtn}, 'content']" :content="companyInfo.describe">
                                {{companyInfo.describe}}
                            <span v-if="showBtn" class="see-more" @click="showMore">展开</span>
                        </p>
                    </div>
                </div>
                <div class="company-contact">
                    <div class="contact">
                        <p class="title">企业地址</p>
                        <p class="info">{{companyInfo.address}}</p>
                    </div>
                    <div class="contact">
                        <p class="title">联系电话</p>
                        <p class="info">{{companyInfo.phoneNum}}</p>
                    </div>
                </div>
              </div>
              <!-- 购买按钮 -->
              <div class="block-btn compatibility" v-if="purchaseUrl != 'http://'"></div>
           </div>
      </div>
      <div class="buy-btn compatibility" v-if="purchaseUrl != 'http://'">
            <div class="btn"><a :href="purchaseUrl">再次购买</a></div>
        </div>
  </div>
</template>

<script>
import bubbles from '@/components/bubbles/index'
import { getBaseInfo  } from '@/api/suyuan'
import { getQueryVariable  } from '@/utils/index'
export default {
  name: 'planA',
  components:{bubbles},
  data(){
      return {
        currentIndex:0,//轮播小点
        //物品的信息
        goodsInfo:{},
        //产品介绍
        productInfo: {},
        map:null,//地图对象
        goodsList:[],
        showBtn: false, //查看“更多”按钮
        //企业介绍
        companyInfo:{
            imgList:'',
            companyName:'',
            describe:'',
            phoneNum:'',
            address:''
        },
        //地图标注图片
        imgSrc: require('../../assets/planB/ico_location.svg'),
        //成长过程
        growthList:[],
        batchsCode:'',//初次进入时的参数
        purchaseUrl:'',//立即购买链接
      }
  },
  created(){
      //从url中获取参数
      this.batchsCode = getQueryVariable('batchsCode');
  },
  mounted(){
      this.ifOverflow();
      setTimeout( () => {
          this.initMap();
      },0)
      //获取页面基础信息
     this.getBaseInfo();
     this.drawPoint(1)
  },
  methods:{
        /**
         * 跳转到过程详情页面
         */
        toDetail(val){
            this.$router.push({name:'BplantsGrow', params:{processId: val,batchsCode:this.batchsCode}})
        },
        /**
         * 获取页面基础信息
         */
        getBaseInfo() {
            getBaseInfo({batchsCode:this.batchsCode}).then( res => {
                let data = res.data;
                //商品信息
                this.goodsInfo.name = data.goodsName;
                this.goodsInfo.num = data.goodsUuid;
                this.goodsInfo.newTime = data.lastQueryTime;
                this.goodsInfo.queryNum = data.queryNum;

                //头部轮播图
                this.goodsList = data.goodsImageList.map( (item) => { return  {img:item} });

                // 产品介绍
                this.productInfo = data.goodsIntroduceList;

                //成长过程
                this.growthList = data.processList;

                //企业介绍
                if(data.company != null){
                    this.companyInfo.companyName = data.company.companyName;
                    this.companyInfo.imgList = data.company.imageUrl;
                    this.companyInfo.describe = data.company.describe;
                    this.companyInfo.phoneNum = data.company.phoneNum;
                    this.companyInfo.address = data.company.address;
                }

                //立即购买
                this.purchaseUrl = data.purchaseUrl;

                //设置浏览器名称
                
                document.title = this.goodsInfo.name;
                //是否超出
                this.ifOverflow();
            })
        },
        /**
         * 判断内容是否超出
         */
        ifOverflow(){
            setTimeout( () => {
                var oScrollHeight = document.getElementById('content').scrollHeight;
                var oOffsetHeight = document.getElementById('content').offsetHeight;
                this.showBtn = oScrollHeight > oOffsetHeight ;
                console.log(this.showBtn,666);
            },0)
        },
        /**
         * 查看更多方法
         */
        showMore(){
            this.showBtn = false;
            document.getElementById('content').style.maxHeight  = 'none'
        },
        initMap(){
            this.map = new BMap.Map('map');
            let point = new BMap.Point(116.399, 39.910);
            // let img = this.drawPoint(1);
            this.addPolyLine();
            this.map.centerAndZoom(point, 15);
        },
        /** 绘制轨迹 
         * @param {Array} arr 经纬度集合
         */
        addPolyLine(arr) {
            arr = [new BMap.Point(116.399, 39.910),
                new BMap.Point(116.405, 39.920),
                new BMap.Point(116.425, 39.900)
            ];
            let polyLine = new BMap.Polyline(arr, {
                strokeColor: '#2D81FF',
                strokeWeight: 3,
                strokeOpacity: 1
            });
            this.map.addOverlay(polyLine);
        },
        /** 
         * 添加标注
         */
        addMarker(img) {
            let point = new BMap.Point(116.399, 39.910);
            const icon = new BMap.Icon(img, new BMap.Size(50, 55), {
                anchor: new BMap.Size(25, 55)
            });
            const marker = new BMap.Marker(point, {
                icon: icon,
            });
            this.map.addOverlay(marker);
        },
        /**
         * 绘制标注点
         * @param {String} 需要绘制的文字
         */
        drawPoint(val) {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            canvas.width = 50;
            canvas.height = 55;
            const imageWidth = canvas.width;
            const imageHeight = canvas.height;
            ctx.fillStyle = 'transparent';
            ctx.fillRect(0, 0, imageWidth, imageHeight);
            let img = new Image();
            img.onload = () => {
                ctx.drawImage(img, 0, 0, 50, 55);
                ctx.beginPath();
                ctx.fillStyle = '#fff';
                ctx.font = '500 18px PingFangSC-Medium, PingFang SC';
                ctx.fillText(val, 20, 32);
                ctx.closePath();
                let imgUrl = '';
                imgUrl = canvas.toDataURL("image/png");
                this.addMarker(imgUrl)
            };
            img.src = this.imgSrc;
        },
        /**
         顶部轮播图切换
        @param { Number } index 当前页索引
        */
        topSwipeChange(index) {
            //动态设置伪元素背景
            this.currentIndex = index;
            
        }
    }
}
</script>
<style lang="scss" scoped>
    .plan-b {
        width: 100%;
        position: relative;
        .nav {
            width: 100%;
            height: 64px;
            background-color: #2D81FF;
            .top-title {
                position: fixed;
                display: flex;
                align-items: center;
                width: 100%;
                height: 64px;
                box-sizing: border-box;
                padding-top: 22px;
                padding-left: 15px;
                background-color: #2D81FF;
                z-index: 99;
                .top-title-iocn {
                    position:absolute;
                    width: 16px;
                    height: 16px;
                    background:url('../../assets/planB/back-w.png') no-repeat top center;
                    background-size: cover;
                    z-index: 2;
                }
                .top-title-content {
                    position:absolute;
                    left:calc(50% - 16px);
                    transform: translate(-50%);
                    text-align: center;
                    font-size: 20px;
                    color: #FFF;
                    z-index: 1;
                }
            }
        }
        .content-one {
            width: 100%;
            height: 225px;
            background: linear-gradient( #2D81FF 0%, #F8F9FA 100%);
            .swipe {
                position: relative;
                width: 100%;
                height: 190px;
                padding: 30px 15px 0 15px;
                box-sizing: border-box;
                border-radius: 10px;
                .my-swipe {
                    width: 100%;
                    height: 160px;
                    box-sizing: border-box;
                    border-radius: 10px;
                    img {
                        border-radius: 10px;
                    }
                    .bg-color {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        background: linear-gradient(to bottom, rgba(0,0,0,0.18) 0%,rgba(0,0,0,0) 60%,rgba(0,0,0,0.41) 100%);
                    }
                }
                .goods-name {
                    position: absolute;
                    bottom: 10px;
                    left: 30px;
                    font-size: 20px;
                    font-weight:600;
                    color: #FFFFFF;
                    text-shadow: 0px 2px 6px rgba(0, 0, 0, 0.34);
                }
            }
            .dots {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 10px;
                .dot {
                    width: 6px;
                    height: 6px;
                    background: rgba(255, 255, 255, 0.5);
                    margin-right: 8px;
                    border-radius: 50%;
                    transition: all 0.3s ease;
                    &.active {
                        background: #FFF;
                        width: 20px;
                        border-radius: 6px;
                    }
                    &:last-child {
                    margin-right: 0;
                    }
                }
            }
        }
        .content-two{
            width: 100%;
            padding: 0 15px;
            box-sizing: border-box;
            background-color: rgb(248, 249, 250);
            .goods-info {
                position: relative;
                height: auto;
                width: 100%;
                background: url('../../assets/planB/anti-counterfeiting.svg') no-repeat top right;
                background-size: cover;
                border-radius:10px;
                .goods-detail {
                    height: auto;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 16px;
                    // background: hsla(0, 0%, 100%,0.3);
                    border-radius:10px;
                    overflow: hidden;
                    .detail {
                        display: flex;
                        width: 100%;
                        margin-bottom: 2px;
                        font-size: 12px;
                        .title {
                            width: 72px;
                            color: #262626;
                        }
                        .content {
                            color:#595959;
                            margin-left: 10px;
                        }
                    }
                    .tip {
                        position: absolute;
                        top:0;
                        left: 16px;
                        transform: translate(0,-50%);
                        display: inline-block;
                        margin-left: 0px;
                        padding: 2px 6px;
                        font-size: 12px;
                        color: #FFFFFF;
                        background: #FB8502;
                        border-radius: 2px;
                    }
                    .last {
                        margin-bottom:0;
                    }
                }
            }
            .introduce {
                width: 100%;
                border-radius:20px 20px 0px 0px;
                box-sizing: border-box;
                margin-top: 42px;
                //产品介绍
                .introduce-title {
                    position: relative;
                    width: 100%;
                    height: 30px;
                    .title {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        width: 177px;
                        height: 30px;
                        text-align: center;
                        background: url(../../assets/planB/bg_title.png) no-repeat top center;
                        background-size: cover;
                        .title-name {
                            height: 30px;
                            font-weight:600;
                            color:#262626;
                            line-height: 30px;
                            letter-spacing: 5px;
                            margin-left: 44px;
                            font-size: 20px;
                        }
                    }
                }
                .introduce-info {
                    position: relative;
                    width: 343px;
                    max-height: 241px;
                    margin: 0 auto;
                    box-sizing: border-box;
                    padding: 22px 16px 22px 16px;
                    background-color: #fff;
                    border-radius:10px;
                    margin-top: 20px;
                    .introduce-detail {
                        height: 100%;
                        width: 100%;
                        max-height: 195px;
                        overflow:auto;
                        .detail {
                            display: flex;
                            width: 100%;
                            margin-bottom: 8px;
                            font-size: 14px;
                            .title {
                                height: 20px;
                                line-height: 20px;
                                width: 66px;
                                color: #262626;
                            }
                            .content {
                                flex: 1;
                                color:#8C8C8C;
                                margin-left: 10px;
                                line-height: 20px;
                            }
                        }
                    }
                    .right-icon {
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 87px;
                        height: 79px;
                        background: url(../../assets/planB/product-introduction.png) no-repeat top center;
                        background-size: cover;
                    }
                    .left-icon {
                        position: absolute;
                        left: 4px;
                        top: -10px;
                        // transform: translate(0,-50%);
                        width: 14px;
                        height: 26px;
                        background: url(../../assets/planB/product-introductionC.png) no-repeat top center;
                        background-size: cover;
                    }
                }
                //物流地图
                .logistics-title {
                    position: relative;
                    width: 100%;
                    height: 30px;
                    margin-top: 30px;
                    .title {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        width: 177px;
                        height: 30px;
                        text-align: center;
                        background: url(../../assets/planB/bg_title.png) no-repeat top center;
                        background-size: cover;
                        .title-name {
                            height: 30px;
                            font-weight:600;
                            color:#262626;
                            line-height: 30px;
                            letter-spacing: 5px;
                            margin-left: 44px;
                            font-size: 20px;
                        }
                    }
                }
                .map-info {
                    position: relative;
                    width: 100%;
                    height: 196px;
                    margin-top: 40px;
                    box-sizing: border-box;
                    padding: 16px 16px;
                    border-radius:10px;
                    background: url(../../assets/planB/decorate-logistics.png) no-repeat top center;
                    background-size: cover;
                    .left-top-icon {
                        width: 28px;
                        height: 25px;
                        position: absolute;
                        top:-17px;
                        left: 6px;
                        background: url(../../assets/planA/decorate-logistics.png) no-repeat top center;
                        background-size: cover;
                    }
                    .map {
                        position: absolute;
                        top: -20px;
                        left: 0;
                        width: 100%;
                        height: 200px;
                        padding: 0 15px;
                        box-sizing: border-box;
                        border-radius: 10px;
                        #map {
                            width: 100%;
                            height: 200px;
                            border-radius: 10px;
                        }
                    }
                }
                //成长过程
                .grow-title {
                    position: relative;
                    width: 100%;
                    height: 30px;
                    margin-top: 45px;
                    .title {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        width: 177px;
                        height: 30px;
                        text-align: center;
                        background: url(../../assets/planB/bg_title.png) no-repeat top center;
                        background-size: cover;
                        .title-name {
                            height: 30px;
                            font-weight:600;
                            color:#262626;
                            line-height: 30px;
                            letter-spacing: 5px;
                            margin-left: 44px;
                            font-size: 20px;
                        }
                    }
                }
                .grow-info {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: 20px;
                    box-sizing: border-box;
                    // background-color: #fff;
                    .info-content-left {
                        position: relative;
                        display: flex;
                        width: 100%;
                        height: 90px;
                        margin-bottom: 16px;
                        .info-img {
                            position: relative;
                            width: 78px;
                            height: 90px;
                            .border {
                                position:absolute;
                                top: 50%;
                                left:50%;
                                transform: translate(-50%,-50%);
                                width: 78px;
                                height: 90px;
                                background: rgba(45,129,255,0.1);
                                position: relative;
                                -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                                clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                            }
                            .img{
                                position:absolute;
                                top: 50%;
                                left:50%;
                                transform: translate(-50%,-50%);
                                width: 58px;
                                height: 70px;
                                object-fit: cover;
                                -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                                clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                                z-index: 2;
                            }
                            .layer {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: url('../../assets/planB/product-introductionD.png') no-repeat bottom center;
                                background-size: cover;
                                z-index: 2;
                            }
                            
                        }
                        .info {
                            flex: 1;
                            position:relative;
                            box-sizing: border-box;
                            padding-bottom: 9px;
                            padding-left: 16px;
                            padding-right: 16px;
                            margin-left: 10px;
                            background-color: #fff;
                            height: 78px;
                            border-radius: 10px 10px 10px 0px;
                            .bubbles {
                                padding-top:12px;
                                height: 30px;
                                line-height: 30px;
                                width: 100%;
                                border-bottom: 1px solid rgba(240,240,240,0.5);
                                font-size: 16px;
                                color: #262626;
                            }
                            .date {
                                width: 100%;
                                height:21px;
                                line-height: 21px;
                                font-size:14px;
                                padding-top: 5px;
                                color:rgba(140,140,140,1);
                            }
                            .left-bottom {
                                position:absolute;
                                left: -15px;
                                bottom:0;
                                width: 15px;
                                height: 9px;
                                background:url('../../assets/planB/left-bottom.png') no-repeat top center;
                                background-size: cover;
                            }
                            .arrow {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                right: 16px;
                                top: 26px;
                                width: 24px;
                                height: 24px;
                                background-color: #FB8502;
                                border-radius: 50%;
                                .arrow-icon {
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background:url('../../assets/planA/arrow.png') no-repeat top center;
                                    background-size: cover;
                                }
                            }
                        }
                        .info::before {
                            content:'';
                            position:absolute;
                            top:0;
                            left:0;
                            height: 78px;
                            width:100%;
                            background:url('../../assets/planB/left-process-bg.png') no-repeat bottom center;
                            background-size: cover;
                        }
                    }
                    .info-content-right {
                        display: flex;
                        height: 90px;
                        flex-direction: row-reverse;
                        padding-bottom: 16px;
                        .info-img {
                            position: relative;
                            width: 78px;
                            height: 90px;
                            .border {
                                position:absolute;
                                top: 50%;
                                left:50%;
                                transform: translate(-50%,-50%);
                                width: 78px;
                                height: 90px;
                                background: rgba(45,129,255,0.1);
                                position: relative;
                                -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                                clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                            }
                            .img{
                                position:absolute;
                                top: 50%;
                                left:50%;
                                transform: translate(-50%,-50%);
                                width: 58px;
                                height: 70px;
                                object-fit: cover;
                                -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                                clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
                                z-index: 2;
                            }
                            .layer {
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background: url('../../assets/planB/product-introductionD.png') no-repeat bottom center;
                                background-size: cover;
                                z-index: 2;
                            }
                        }
                        .info {
                            flex: 1;
                            position: relative;
                            box-sizing: border-box;
                            padding-bottom: 9px;
                            padding-left: 16px;
                            padding-right: 16px;
                            margin-right: 10px;
                            background-color: #fff;
                            height: 78px;
                            border-radius: 10px 10px 0px 10px;
                            .bubbles {
                                padding-top:12px;
                                height: 30px;
                                line-height: 30px;
                                width: 100%;
                                border-bottom: 1px solid rgba(240,240,240,0.5);
                                font-size: 16px;
                                color: #262626;
                            }
                            .right-bottom {
                                position:absolute;
                                right: -15px;
                                bottom:0;
                                width: 15px;
                                height: 9px;
                                background:url('../../assets/planB/right-bottom.png') no-repeat top center;
                                background-size: cover;
                            }
                            .date {
                                width: 100%;
                                height:21px;
                                line-height: 21px;
                                font-size:14px;
                                padding-top: 5px;
                                color:rgba(140,140,140,1);
                            }
                            .arrow {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                right: 16px;
                                top: 26px;
                                width: 24px;
                                height: 24px;
                                background-color: #FB8502;
                                border-radius: 50%;
                                .arrow-icon {
                                    width: 16px;
                                    height: 16px;
                                    border-radius: 50%;
                                    background:url('../../assets/planA/arrow.png') no-repeat top center;
                                    background-size: cover;
                                }
                            }
                        }
                        .info::before {
                            content:'';
                            position:absolute;
                            top:0;
                            left:0;
                            height: 78px;
                            width:100%;
                            background:url('../../assets/planB/right-process-bg.png') no-repeat bottom center;
                            background-size: cover;
                        }
                        
                    }
                }
                // 企业介绍
                .company-title {
                    position: relative;
                    width: 100%;
                    height: 30px;
                    margin-top: 30px;
                    .title {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        left: 50%;
                        transform: translate(-50%);
                        width: 177px;
                        height: 30px;
                        text-align: center;
                        background: url(../../assets/planB/bg_title.png) no-repeat top center;
                        background-size: cover;
                        .title-name {
                            height: 30px;
                            font-weight:600;
                            color:#262626;
                            line-height: 30px;
                            letter-spacing: 5px;
                            margin-left: 44px;
                            font-size: 20px;
                        }
                    }
                }
                .company-info {
                    width: 100%;
                    margin: 0 auto;
                    margin-top: 20px;
                    box-sizing: border-box;
                    background-color: #FFF;
                    border-radius:10px;
                    // padding-bottom:15px;
                    .swipe {
                        position:relative;
                        width: 100%;
                        height:160px;
                        .my-swipe {
                            width: 100%;
                            height: 160px;
                            .img {
                                border-radius: 10px;
                            }
                        }
                        .left-icon {
                            position:absolute;
                            top:-10px;
                            left:10px;
                            width:14px;
                            height:26px;
                            background: url(../../assets/planB/c.png) no-repeat top center;
                            background-size: cover;
                        }
                    }
                    
                    .company-content {
                        width: 100%;
                        margin-top: 16px;
                        box-sizing: border-box;
                        padding-bottom: 12px;
                        padding-left:16px;
                        padding-right: 16px;
                        .company {
                            width:100%;
                            height:auto;
                            padding-bottom:12px;
                            border-bottom: 1px solid rgba(240, 240, 240, .6);
                            .name {
                                width: 100%;
                                font-size:16px;
                                font-weight: 600;
                                color:rgba(38,38,38,1);
                                line-height:22.5px;
                            }
                            .content {
                                position: relative;
                                width: 100%;
                                margin-top: 8px;
                                font-size: 14px;
                                max-height: 84px;
                                color: #8C8C8C;
                                text-align: justify;
                                overflow: hidden;
                            }

                            .box::before,
                            .box::after {
                                content: attr(content);
                                overflow: hidden;
                                position: absolute;
                                width: 100%;
                                left: 0;
                                top: 0;
                                color: rgba(140, 140, 140, 1);
                                line-height: 21px;
                            }

                            .box::before {
                                max-height: 61px;
                                z-index: 2;
                                background: #fff;
                            }

                            .box::after {
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-box-sizing: border-box;
                                box-sizing: border-box;
                                -webkit-line-clamp: 4;
                                text-indent: -6em;
                                padding-right: 2em;
                            }

                            .box {
                                color: #fff;
                                .see-more {
                                    position: absolute;
                                    right: 0;
                                    bottom: 0;
                                    z-index: 3;
                                    color: #000;
                                    line-height: 21px;
                                }
                            } 
                        }
                        
                    }
                    .company-contact {
                        width: 100%;
                        height: 61px;
                        padding-top: 0px;
                        box-sizing: border-box;
                        padding-left:16px;
                        .contact {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            margin-bottom: 8px;
                            
                            .title {
                                min-width: 56px;
                                font-size:14px;
                                color:rgba(38,38,38,1);
                            }
                            .info {
                                flex: 1;
                                margin-left: 20px;
                                font-size:14px;
                                color:rgba(140,140,140,1);
                            }
                        }
                    }
                }
                .block-btn {
                    width: 100%;
                    height: 60px;
                    margin-top: 25px;
                }
            }
            
        }
        .buy-btn {
            position: fixed;
            display: flex;
            justify-content: center;
            align-items: center;
            bottom: 0px;
            width: 100%;
            height: 60px;
            margin-bottom: env(safe-area-inset-bottom);
            background-color: #fff;
            z-index: 999;
            .btn {
                width: 343px;
                height: 44px;
                text-align: center;
                color:#fff;
                font-size: 20px;
                line-height: 44px;
                font-weight:600;
                background: linear-gradient(135deg, #30F2F8 0%, #2D81FF 100%);
                border-radius:22px;
                z-index: 9;
                a {
                    display: inline-block;
                    width: 100%;
                    height: 100%;
                    color:#fff;
                    border-radius:22px;
                }
            }
        }
    }
</style>
<style lang="scss">
    .plan-b {
        .content-one {
            .van-swipe__indicators {
                display: none;
            }

            .van-swipe-item {
                border-radius: 10px !important;
            }
            .van-swipe__track {
                border-radius: 10px !important;
            }
            .van-image  {
                border-radius: 10px !important;
                .van-image__img {
                    border-radius: 10px !important;
                }
            }
        }
        
        .company-info {
            .van-image  {
                border-radius: 10px !important;
                .van-image__img {
                    border-top-left-radius: 10px !important;
                    border-top-right-radius: 10px !important;
                }
            }
        }
    }
</style>
